import apiClient from '@/services'

const addClaim = (data) => {
  return apiClient.post('/simulate', data)
}

const getGame = (teamWeekDayId) => {
  return apiClient.get(`/game-play-status/${teamWeekDayId}`)
}

const getOpenWeek = (simulationId, teamId) => {
  return teamId
    ? apiClient.get(`/open-week/${simulationId}/${teamId}`)
    : apiClient.get(`/open-week/${simulationId}`)
}

const getTeamWeeks = (id) => {
  return apiClient.get(`/systems/${id}`)
}

const getWorkAllocations = (teamWeekId) => {
  return apiClient.get(`/work-allocation/${teamWeekId}`)
}

export { addClaim, getGame, getOpenWeek, getTeamWeeks, getWorkAllocations }
