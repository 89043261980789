import apiClient from '@/services'
import axios from 'axios'

let cancelGetSimulationTeams = null

const addSimulation = (data) => {
  return apiClient.post('/simulation', data)
}

const addSimulationTeam = (id, formData) => {
  return apiClient.post(`/simulation/${id}/teams`, formData)
}

const deleteSimulation = (id) => {
  return apiClient.delete(`/simulation/${id}`)
}

const deleteSimulationTeam = (id, data) => {
  return apiClient.delete(`/simulation/${id}/teams`, { data: data })
}

const deleteSimulationUser = (id, data) => {
  return apiClient.delete(`/simulation/${id}/users`, { data: data })
}

const getSimulationByCode = (code) => {
  return apiClient.get(`/simulation/code/${code}`)
}

const getSimulationById = (id) => {
  return apiClient.get(`/simulate/${id}`)
}

const getSimulationTeams = (id) => {
  if (cancelGetSimulationTeams) {
    cancelGetSimulationTeams('Operation canceled due to new teams request.')
  }

  // Create a new cancel token
  let cancelToken
  const source = axios.CancelToken.source()
  cancelGetSimulationTeams = source.cancel
  cancelToken = source.token
  return apiClient.get(`/simulation/${id}/teams`, { cancelToken })
}

const getSimulations = () => {
  return apiClient.get('/simulations')
}

const getSimulationUsers = (id) => {
  return apiClient.get(`/simulation/${id}/users`)
}

const updateSimulation = (id, formData) => {
  return apiClient.post(`/simulation/${id}`, formData)
}

const updateSimulationTeam = (id, formData) => {
  return apiClient.post(`/simulation/${id}/teams/update`, formData)
}

const upsertSimulationUsers = (id, data) => {
  return apiClient.post(`/simulation/${id}/users`, data)
}

export {
  addSimulation,
  addSimulationTeam,
  deleteSimulation,
  deleteSimulationTeam,
  deleteSimulationUser,
  getSimulationByCode,
  getSimulationById,
  getSimulationTeams,
  getSimulations,
  getSimulationUsers,
  updateSimulation,
  updateSimulationTeam,
  upsertSimulationUsers
}
