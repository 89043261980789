import { claimsProcessedChart, accuracyChart, profitabilityChart, volumeChart } from '@/data/charts'
import * as types from '../mutation-types'
import { ThemeColors } from '@/utils'
import { chartTooltip } from '@/utils'
import { mapValues } from 'lodash'
const colors = ThemeColors()
import { dashboardService } from '@/services'

const state = {
  vmbCharts: null,
  accuracyChart: null,
  claimsProcessedChart: null,
  profitabilityChart: null
}

const getters = {
  vmbCharts: (state) => state.vmbCharts,
  accuracyChart: (state) => state.accuracyChart,
  claimsProcessedChart: (state) => state.claimsProcessedChart,
  profitabilityChart: (state) => state.profitabilityChart
}

const mutations = {
  [types.FETCH_VMBCHARTSDATA_SUCCESS](state, payload) {
    state.vmbCharts = payload
  },
  [types.FETCH_VMBCHARTSDATA_FAILURE](state) {
    state.vmbCharts = null
  },
  setAccuracyChart(state, payload) {
    const accuracyData = payload.accuracy
    const chartColors = [
      {
        borderColor: colors.themeColor1,
        backgroundColor: colors.themeColor1_10,
        borderWidth: 1
      },
      {
        borderColor: colors.themeColor2,
        backgroundColor: colors.themeColor2_10,
        borderWidth: 1
      }
    ]
    const accuracyChart = {
      labels: accuracyData.labels,
      datasets: _.merge(accuracyData.datasets, chartColors)
    }

    state.accuracyChart = accuracyChart
  },
  setClaimsProcessedChart(state, payload) {
    const claimsProcessedData = payload.claimsProcessed
    const claimsProcessedChart = {
      labels: claimsProcessedData.labels,
      datasets: [
        {
          label: 'This Week',
          borderColor: colors.themeColor1,
          backgroundColor: colors.themeColor1_10,
          data: claimsProcessedData.data,
          borderWidth: 1
        }
      ]
    }

    state.claimsProcessedChart = claimsProcessedChart
  },
  setProfitabilityChart(state, payload) {
    const profitabilityData = payload.profitability
    const chartProperties = [
      {
        borderColor: colors.themeColor1,
        backgroundColor: colors.themeColor1_10,
        borderWidth: 1
      },
      {
        borderColor: colors.themeColor2,
        backgroundColor: colors.themeColor2_10,
        borderWidth: 1
      },
      {
        borderColor: colors.primaryColor,
        backgroundColor: colors.themeColor3_10,
        borderWidth: 1
      }
    ]
    const profitabilityChart = {
      labels: profitabilityData.labels,
      datasets: _.merge(profitabilityData.datasets, chartProperties)
    }

    state.profitabilityChart = profitabilityChart
  }
}

const actions = {
  async fetchVmbChartData({ commit, dispatch }, { simulationId, teamId = null }) {
    try {
      await commit('loading/SET_VMB_PERFORMANCE_CHARTS_LOADING', true, { root: true })
      const { data } = await dashboardService.getVmbChartData(simulationId, teamId)
      await commit('setAccuracyChart', data.data)
      await commit('setClaimsProcessedChart', data.data)
      await commit('setProfitabilityChart', data.data)
      await commit('loading/SET_VMB_PERFORMANCE_CHARTS_LOADING', false, { root: true })
    } catch (error) {
      await commit('loading/SET_VMB_PERFORMANCE_CHARTS_LOADING', false, { root: true })
      const notification = {
        type: 'error',
        title: `Error occurs when fetching VMB chart data`,
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async setVmbChartsData({ commit }, payload) {
    await commit('setAccuracyChart', payload.data)
    await commit('setClaimsProcessedChart', payload.data)
    await commit('setProfitabilityChart', payload.data)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
