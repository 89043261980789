import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthRequired from './utils/AuthRequired'
import i18n from '@/i18n'
import { role } from '@/constants/config'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "app" */ './views/app'),
    meta: { authorize: [] },
    beforeEnter: AuthRequired,
    children: [
      {
        path: '',
        component: () =>
          import(/* webpackChunkName: "Home" */ './views/app/simulations/MySimulations'),
        meta: { authorize: [] },
        name: 'my-simulations'
      },
      {
        path: 'my-simulations/:dynamic_code',
        redirect: '/my-simulations/:dynamic_code/home',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: 'home',
            name: 'home',
            component: () => import(/* webpackChunkName: "Home" */ './views/app/Home'),
            meta: { authorize: [] }
          },
          {
            path: 'rules',
            name: 'rules',
            component: () => import(/* webpackChunkName: "Rules" */ './views/app/Rules'),
            meta: { authorize: [] }
          },
          {
            path: 'policies',
            name: 'policies',
            component: () => import(/* webpackChunkName: "Policies" */ './views/app/Policies'),
            meta: { authorize: [] }
          },
          {
            path: 'lodgements',
            name: 'lodgements',
            component: () => import(/* webpackChunkName: "Lodgements" */ './views/app/Lodgements'),
            meta: { authorize: [] }
          },
          {
            path: 'vmb',
            name: 'vmb',
            component: () => import('./views/app/dashboard'),
            children: [
              {
                meta: { id: 99, title: 'Dashboard', authorize: [] },
                name: 'dashboard',
                path: 'dashboard',
                component: () =>
                  import(/* webpackChunkName: "dashboards" */ './views/app/dashboard/Dashboards')
              },
              {
                meta: { id: 3, title: i18n.t('menu.vmb-menu.what-went-well'), authorize: [] },
                name: 'what-went-well',
                path: 'what-went-well',
                component: () =>
                  import(
                    /* webpackChunkName: "dashboards" */ './views/app/dashboard/SurveyObservationDetails'
                  )
              },
              {
                meta: { id: 4, title: i18n.t('menu.vmb-menu.communications'), authorize: [] },
                name: 'communication',
                path: 'communication',
                component: () =>
                  import(
                    /* webpackChunkName: "dashboards" */ './views/app/dashboard/SurveyObservationDetails'
                  )
              },
              {
                name: 'feedback',
                path: 'feedback',
                meta: { id: 5, title: i18n.t('menu.vmb-menu.feedback'), authorize: [] },
                component: () =>
                  import(
                    /* webpackChunkName: "dashboards" */ './views/app/dashboard/SurveyObservationDetails'
                  )
              },
              {
                path: 'performance',
                meta: { id: 6, title: i18n.t('menu.vmb-menu.performance'), authorize: [] },
                component: () =>
                  import(/* webpackChunkName: "dashboards" */ './views/app/dashboard/Performance')
              },
              {
                name: 'problems',
                path: 'problems',
                meta: { id: 1, title: i18n.t('menu.vmb-menu.problems'), authorize: [] },
                component: () =>
                  import(
                    /* webpackChunkName: "dashboards" */ './views/app/dashboard/SurveyObservationDetails'
                  )
              },
              {
                name: 'actions',
                path: 'actions',
                meta: { id: 2, title: i18n.t('menu.vmb-menu.actions'), authorize: [] },
                component: () =>
                  import(
                    /* webpackChunkName: "dashboards" */ './views/app/dashboard/SurveyObservationDetails'
                  )
              },
              {
                path: 'people',
                meta: { id: 7, title: i18n.t('menu.vmb-menu.people'), authorize: [] },
                name: 'people',
                component: () =>
                  import(/* webpackChunkName: "dashboards" */ './views/app/dashboard/People')
              }
            ]
          },
          {
            component: () => import(/* webpackChunkName: "admin" */ './views/app/admin'),
            meta: { authorize: [role.ADMIN, role.GAMECREATOR] },
            name: 'management',
            path: 'management',
            redirect: 'management/game-control',
            children: [
              {
                component: () =>
                  import(/* webpackChunkName: "scores" */ './views/app/admin/GameControl'),
                meta: {
                  id: 'game-control',
                  title: 'Game Control',
                  authorize: [role.ADMIN, role.GAMECREATOR]
                },
                name: 'game-control',
                path: 'game-control'
              },
              {
                component: () =>
                  import(/* webpackChunkName: "scores" */ './views/app/admin/ScoreCentre'),
                meta: { id: 'scores', title: 'Scores', authorize: [role.ADMIN, role.GAMECREATOR] },
                name: 'scores',
                path: 'scores'
              },
              {
                component: () =>
                  import(/* webpackChunkName: "audit-log" */ './views/app/admin/AuditLog'),
                meta: {
                  id: 'audit-log',
                  title: 'Audit Log',
                  authorize: [role.ADMIN, role.GAMECREATOR]
                },
                name: 'audit-log',
                path: 'audit-log'
              },
              {
                component: () =>
                  import(/* webpackChunkName: "admin" */ './views/app/admin/management/Users'),
                meta: { id: 'users', authorize: [role.ADMIN, role.GAMECREATOR] },
                name: 'users',
                path: 'users'
              },
              {
                component: () =>
                  import(/* webpackChunkName: "users" */ './views/app/admin/management/Management'),
                meta: { id: 'roles', authorize: [role.ADMIN] },
                name: 'roles',
                path: 'roles'
              },
              {
                component: () =>
                  import(/* webpackChunkName: "teams" */ './views/app/admin/management/Teams'),
                meta: { id: 'teams', authorize: [role.ADMIN, role.GAMECREATOR] },
                name: 'teams',
                path: 'teams'
              },
              {
                component: () =>
                  import(
                    /* webpackChunkName: "approval-codes" */ './views/app/admin/management/ApprovalCodes'
                  ),
                meta: { id: 'approval-codes', authorize: [role.ADMIN] },
                name: 'approval-codes',
                path: 'approval-codes'
              },
              {
                component: () =>
                  import(
                    /* webpackChunkName: "claim-master" */ './views/app/admin/management/ClaimMaster'
                  ),
                meta: { id: 'claim-master', authorize: [role.ADMIN] },
                name: 'claim-master',
                path: 'claim-master'
              }
            ]
          }
        ]
      },
      {
        path: 'users',
        component: () => import(/* webpackChunkName: "users" */ './views/app/admin/Users'),
        meta: { authorize: [role.ADMIN] },
        name: 'global-user-management'
      }
    ]
  },
  {
    path: '/error',
    component: () => import(/* webpackChunkName: "error" */ './views/Error')
  },
  {
    path: '/work/:dynamic_code',
    redirect: '/work/:dynamic_code/allocations',
    beforeEnter: AuthRequired,
    meta: { authorize: [role.ADMIN, role.GAMECREATOR, role.USER] },
    component: () => import(/* webpackChunkName: "work" */ './views/work'),
    children: [
      {
        path: 'allocations',
        component: () => import(/* webpackChunkName: "work" */ './views/work/Allocations'),
        meta: { authorize: [role.ADMIN, role.GAMECREATOR, role.USER] },
        name: 'allocations'
      }
    ]
  },
  {
    path: '/work',
    beforeEnter: AuthRequired,
    meta: { authorize: [role.ADMIN, role.GAMECREATOR, role.USER] },
    component: () => import(/* webpackChunkName: "work" */ './views/work'),
    children: [
      {
        path: 'allocations',
        component: () => import(/* webpackChunkName: "work" */ './views/work/Allocations'),
        meta: { authorize: [role.ADMIN, role.GAMECREATOR, role.USER] }
      }
    ]
  },
  {
    path: '/user',
    component: () => import(/* webpackChunkName: "user" */ './views/user'),
    redirect: '/user/login',
    children: [
      {
        component: () => import(/* webpackChunkName: "user" */ './views/user/Login'),
        name: 'login',
        path: 'login'
      },
      {
        component: () => import(/* webpackChunkName: "user" */ './views/user/Register'),
        name: 'register',
        path: 'register'
      },
      {
        component: () => import(/* webpackChunkName: "user" */ './views/user/ForgotPassword'),
        name: 'forgot-password',
        path: 'forgot-password'
      },
      {
        component: () => import(/* webpackChunkName: "user" */ './views/user/ResetPassword'),
        path: 'reset-password',
        path: 'reset-password'
      }
    ]
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "error" */ './views/Error')
  }
]

const router = new VueRouter({
  linkActiveClass: 'active',
  routes,
  mode: 'history'
})

export default router
