export function initializeTeam() {
  return {
    avatar: '',
    id: null,
    name: '',
    simulationId: null,
    teamId: null
  }
}

export function initializeUser() {
  return {
    accountRoleId: null,
    avatar: '',
    confirmPassword: '',
    email: '',
    firstName: '',
    id: null,
    lastName: '',
    name: '',
    password: '',
    simulations: []
  }
}

export function initializeUserTableFields() {
  return [
    {
      key: 'avatar',
      label: 'Name',
      sortable: false,
      thClass: 'w-25',
      tdClass: 'align-middle'
    },
    {
      key: 'email',
      label: 'Email',
      sortable: true,
      thClass: 'w-25',
      tdClass: 'align-middle'
    },
    {
      key: 'accountRoleName',
      label: 'Role',
      sortable: true,
      thClass: 'w-25',
      tdClass: 'align-middle'
    },
    {
      key: 'actions',
      label: 'Actions',
      thClass: 'w-5',
      tdClass: 'align-middle action-column'
    }
  ]
}

export function initializeVmbModules() {
  return {
    actions: [],
    communication: [],
    feedback: [],
    people: [],
    problems: [],
    'what-went-well': []
  }
}

export function initializeSimulationSelection() {
  return {
    id: null,
    simulationRoleId: null,
    simulationRoleName: '',
    simulationTeamId: null,
    simulationTeamName: ''
  }
}

export function initializeSimulationUserTableFields() {
  return [
    {
      key: 'avatar',
      label: 'Name',
      sortable: false,
      thClass: 'w-25',
      tdClass: 'align-middle'
    },
    {
      key: 'email',
      label: 'Email',
      sortable: true,
      thClass: 'w-30',
      tdClass: 'align-middle'
    },
    {
      key: 'simulationRoleName',
      label: 'Role',
      sortable: true,
      thClass: 'w-20',
      tdClass: 'align-middle'
    },
    {
      key: 'simulationTeamName',
      label: 'Team',
      sortable: true,
      thClass: 'w-20',
      tdClass: 'align-middle'
    },
    {
      key: 'actions',
      label: 'Actions',
      thClass: 'w-5',
      tdClass: 'align-middle action-column'
    }
  ]
}

export function initializeSurvey() {
  return {
    userId: '',
    weekId: '',
    dayId: '',
    whatWentWell: [],
    communication: [],
    feedback: [],
    performance: '',
    problems: [],
    actions: [],
    people: 6,
    anySupport: '',
    createdAt: ''
  }
}
