import store from '@/store'

export default (to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const meta = to.meta
  const authCheck = store.getters.authCheck
  const currentUser = store.getters.currentUser
  if (meta) {
    if (!authCheck) {
      // not logged in so redirect to login page with the return url
      localStorage.clear()

      // store redirect url
      if (to && to.name !== 'login') {
        localStorage.setItem('redirectUrl', to.fullPath)
      }

      return next({ name: 'login' })
    }

    // check if route is restricted by role
    if (
      currentUser &&
      meta.authorize.length &&
      !meta.authorize.includes(currentUser.accountRoleId)
    ) {
      // role not authorised so redirect to home page
      return next({ path: '/' })
    }
  }

  next()
}
