import apiClient from '@/services'

const addVmbItem = (data) => {
  return apiClient.post('/dashboard/survey', data)
}

const getVmbChartData = (simulationId, teamId) => {
  return teamId
    ? apiClient.get(`/dashboard/${simulationId}/graphs?team_id=${teamId}`)
    : apiClient.get(`/dashboard/${simulationId}/graphs`)
}

const getVmbData = (simulationId, teamId) => {
  return teamId
    ? apiClient.get(`/dashboard/${simulationId}/survey?teamId=${teamId}`)
    : apiClient.get(`/dashboard/${simulationId}/survey`)
}

const getVmbObservationData = (simulationId, teamId) => {
  return teamId
    ? apiClient.get(`/dashboard/${simulationId}/observation?team_id=${teamId}`)
    : apiClient.get(`/dashboard/${simulationId}/observation`)
}

const getVmbObservationTypes = () => {
  return apiClient.get('/dashboard/observation-types')
}

const getVmbWeeklyData = (simulationId, teamId) => {
  return teamId
    ? apiClient.get(`/dashboard/${simulationId}/weekly-data?team_id=${teamId}`)
    : apiClient.get(`/dashboard/${simulationId}/weekly-data`)
}

const getVmbYesterdayData = (weekId) => {
  return apiClient.get(`/dashboard/individual-performance/${weekId}`)
}

const updateVmbObservationStatus = (data) => {
  return apiClient.patch('/dashboard/observation', data)
}

export {
  addVmbItem,
  getVmbChartData,
  getVmbData,
  getVmbObservationData,
  getVmbObservationTypes,
  getVmbWeeklyData,
  getVmbYesterdayData,
  updateVmbObservationStatus
}
