const namespaced = true

const state = {
  loadingAuditLogFilters: false,
  loadingRoundBreakdownTable: false,
  loadingScoreCentreOverallCharts: false,
  loadingScoreCentreRoundBreakdownTable: false,
  loadingScoreCentreWeeklyCharts: false,
  loadingSimulation: false,
  loadingSimulations: false,
  loadingSimulationTeams: false,
  loadingSimulationUsers: false,
  loadingTeamWeekDays: false,
  loadingTeamWeeks: false,
  loadingUsers: false,
  loadingVmbData: false,
  loadingVmbPerformanceCharts: false,
  loadingVmbWeeklyData: false,
  loadingVmbYesterdayData: false,
  processingLogin: false,
  processingSimulation: false,
  processingSimulationTeam: false,
  processingSimulationUser: false,
  processingUser: false,
  processingUserImport: false
}

const getters = {
  loadingAuditLogFilters: (state) => state.loadingAuditLogFilters,
  loadingRoundBreakdownTable: (state) => state.loadingRoundBreakdownTable,
  loadingScoreCentreOverallCharts: (state) => state.loadingScoreCentreOverallCharts,
  loadingScoreCentreRoundBreakdownTable: (state) => state.loadingScoreCentreRoundBreakdownTable,
  loadingScoreCentreWeeklyCharts: (state) => state.loadingScoreCentreWeeklyCharts,
  loadingSimulation: (state) => state.loadingSimulation,
  loadingSimulations: (state) => state.loadingSimulations,
  loadingSimulationTeams: (state) => state.loadingSimulationTeams,
  loadingSimulationUsers: (state) => state.loadingSimulationUsers,
  loadingTeamWeekDays: (state) => state.loadingTeamWeekDays,
  loadingTeamWeeks: (state) => state.loadingTeamWeeks,
  loadingUsers: (state) => state.loadingUsers,
  loadingVmbData: (state) => state.loadingVmbData,
  loadingVmbPerformanceCharts: (state) => state.loadingVmbPerformanceCharts,
  loadingVmbWeeklyData: (state) => state.loadingVmbWeeklyData,
  loadingVmbYesterdayData: (state) => state.loadingVmbYesterdayData,
  processingLogin: (state) => state.processingLogin,
  processingSimulation: (state) => state.processingSimulation,
  processingSimulationTeam: (state) => state.processingSimulationTeam,
  processingSimulationUser: (state) => state.processingSimulationUser,
  processingUser: (state) => state.processingUser,
  processingUserImport: (state) => state.processingUserImport
}

const mutations = {
  SET_AUDIT_LOG_FILTERS_LOADING(state, payload) {
    state.loadingAuditLogFilters = payload
  },
  SET_ROUND_BREAKDOWN_TABLE_LOADING(state, payload) {
    state.loadingRoundBreakdownTable = payload
  },
  SET_LOGIN_PROCESSING(state, payload) {
    state.processingLogin = payload
  },
  SET_SCORE_CENTRE_OVERALLCHARTS_LOADING(state, payload) {
    state.loadingScoreCentreOverallCharts = payload
  },
  SET_SCORE_CENTRE_ROUNDBREAKDOWN_LOADING(state, payload) {
    state.loadingScoreCentreRoundBreakdownTable = payload
  },
  SET_SCORE_CENTRE_WEEKLYCHARTS_LOADING(state, payload) {
    state.loadingScoreCentreWeeklyCharts = payload
  },
  SET_SIMULATION_LOADING(state, payload) {
    state.loadingSimulation = payload
  },
  SET_SIMULATIONS_LOADING(state, payload) {
    state.loadingSimulations = payload
  },
  SET_SIMULATION_PROCESSING(state, payload) {
    state.processingSimulation = payload
  },
  SET_SIMULATION_TEAMS_LOADING(state, payload) {
    state.loadingSimulationTeams = payload
  },
  SET_SIMULATION_TEAM_PROCESSING(state, payload) {
    state.processingSimulationTeam = payload
  },
  SET_SIMULATION_USERS_LOADING(state, payload) {
    state.loadingSimulationUsers = payload
  },
  SET_SIMULATION_USER_PROCESSING(state, payload) {
    state.processingSimulationUser = payload
  },
  SET_TEAM_WEEKDAYS_LOADING(state, payload) {
    state.loadingTeamWeekDays = payload
  },
  SET_TEAM_WEEKS_LOADING(state, payload) {
    state.loadingTeamWeeks = payload
  },
  SET_USER_PROCESSING(state, payload) {
    state.processingUser = payload
  },
  SET_USER_IMPORT_PROCESSING(state, payload) {
    state.processingUserImport = payload
  },
  SET_USERS_LOADING(state, payload) {
    state.loadingUsers = payload
  },
  SET_VMB_DATA_LOADING(state, payload) {
    state.loadingVmbData = payload
  },
  SET_VMB_PERFORMANCE_CHARTS_LOADING(state, payload) {
    state.loadingVmbPerformanceCharts = payload
  },
  SET_VMB_WEEKLY_DATA_LOADING(state, payload) {
    state.loadingVmbWeeklyData = payload
  },
  SET_VMB_YESTERDAY_DATA_LOADING(state, payload) {
    state.loadingVmbYesterdayData = payload
  }
}

const actions = {}

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions
}
