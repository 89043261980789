import getSymbolFromCurrency from 'currency-symbol-map'
import moment from 'moment'

export const defaultMenuType = 'menu-default menu-sub-hidden' // 'menu-default', 'menu-sub-hidden', 'menu-hidden';
export const subHiddenBreakpoint = 1440
export const menuHiddenBreakpoint = 768
export const defaultDirection = 'ltr'
export const isDemo = false
export const themeRadiusStorageKey = 'theme_radius'
export const defaultColor = 'sim'

export const colors = [
  'light.purple',
  'dark.purple',
  'light.blue',
  'dark.blue',
  'light.green',
  'dark.green',
  'light.orange',
  'dark.orange',
  'light.red',
  'dark.red'
]

export const role = {
  ADMIN: 1,
  GAMECREATOR: 2,
  USER: 3
}

export const simulationRoles = {
  PARTICIPANT: 1,
  FACILITATOR: 2,
  MASTER: 3
}

export const policyTypes = [
  {
    id: 1,
    name: 'Rent Smart'
  },
  {
    id: 2,
    name: 'Home Plus'
  },
  {
    id: 3,
    name: 'Car Protect'
  }
]

export const claimResults = [
  {
    id: 1,
    result: true,
    name: 'Success'
  },
  {
    id: 2,
    result: false,
    name: 'Failed'
  }
]

export const gameDuration = 90 // seconds
export const gameStartGracePeriod = -5 // seconds
export const defaultLanguage = 'en'
export const defaultLocale = 'en-AU'

export const languages = [
  {
    id: 'en',
    name: 'English'
  },
  {
    id: 'in',
    name: 'Bahasa Indonesia'
  },
  {
    id: 'th',
    name: 'แบบไทย'
  },
  {
    id: 'hi',
    name: 'हिंदी'
  },
  {
    id: 'zhs',
    name: '中文(简体)'
  },
  {
    id: 'zht',
    name: '中文(繁體)'
  }
]

export const locales = [
  { locale: 'en-AU', currency: 'AUD', symbol: getSymbolFromCurrency('AUD') },
  { locale: 'de-DE', currency: 'EUR', symbol: getSymbolFromCurrency('EUR') },
  { locale: 'fr-FR', currency: 'EUR', symbol: getSymbolFromCurrency('EUR') },
  { locale: 'en-GB', currency: 'GBP', symbol: getSymbolFromCurrency('GBP') },
  { locale: 'en-US', currency: 'USD', symbol: getSymbolFromCurrency('USD') },
  { locale: 'en-ZA', currency: 'ZAR', symbol: getSymbolFromCurrency('ZAR') }
]

export const locale = locales.find(
  (loc) => loc.locale === config.VUE_APP_LOCALE || process.env.VUE_APP_LOCALE
)

export const quickAllocations = {
  max: 30,
  step: 5
}

/* functions */
export function getLocale(locale) {
  return locales.find((loc) => loc.locale === locale)
}

export function formatPayment(value) {
  let locale = localStorage.getItem('locale')
  if (!locale || locale === 'null') {
    locale = defaultLocale
  }

  try {
    locale = JSON.parse(locale)
  } catch (e) {}

  let selectedLocale = getLocale(locale)
  return value || parseInt(value) === 0
    ? value.toLocaleString(selectedLocale.locale, {
        style: 'currency',
        currency: selectedLocale.currency
      })
    : 'N/A'
}

export function formatRatePercentage(value, places) {
  return value ? Number(value.toFixed(places)) + '%' : '0%'
}

export function formatBasePageContent(content) {
  let updatedContent = content
  let matches = content.match(/\[(.*?)\]/g)
  if (matches) {
    matches.forEach((m) => {
      let item = m.match(/\[(.*?)\]/)
      if (item) {
        let original = item[0]
        let payment = item[1]
        updatedContent = updatedContent.replace(original, formatPayment(Number(payment)))
      }
    })
  }

  return updatedContent
}

export function formatDate(date) {
  return moment(date).format('DD MMM, YYYY HH:mm')
}

export function createUUID() {
  const pattern = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
  return pattern.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const SEARCH_INPUT_DEBOUNCE = 500

export const simulationStatus = [
  {
    id: 0,
    name: 'Not Started',
    variant: 'light'
  },
  {
    id: 1,
    name: 'In Progress',
    variant: 'warning'
  },
  {
    id: 2,
    name: 'Completed',
    variant: 'success'
  }
]
