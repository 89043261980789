import Vue from 'vue'
import App from './App'
import { BootstrapVue } from 'bootstrap-vue'
import en from './locales/en.json'
import cn from './locales/cn.json'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Notifications from './components/Common/Notification'
import Breadcrumb from './components/Common/Breadcrumb'
import RefreshButton from './components/Common/RefreshButton'
import Colxx from './components/Common/Colxx'
import vuePerfectScrollbar from 'vue-perfect-scrollbar'
import contentmenu from 'v-contextmenu'
import VueLineClamp from 'vue-line-clamp'
import VCalendar from 'v-calendar'
import 'v-calendar/lib/v-calendar.min.css'
import VueScrollTo from 'vue-scrollto'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import Carousel3d from 'vue-carousel-3d'
import VueMoment from 'vue-moment'
import ToggleButton from 'vue-js-toggle-button'
import 'nprogress/nprogress.css'
import NProgress from 'nprogress'
import VTooltip from 'v-tooltip'
import AnimateCSS from 'animate.css'
import RunningLoader from '@/components/Common/RunningLoader.vue'
import i18n from './i18n'

NProgress.configure({ showSpinner: false })
window.Pusher = require('pusher-js')
window.Pusher = new Pusher(config.VUE_APP_WEBSOCKETS_KEY || process.env.VUE_APP_WEBSOCKETS_KEY, {
  cluster: config.VUE_APP_WEBSOCKETS_CLUSTER || process.env.VUE_APP_WEBSOCKETS_CLUSTER
})

Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
// Vue.use(VueI18n)
// const messages = { en: en, cn: cn }
// const locale = getCurrentLanguage()
// const i18n = new VueI18n({
//   locale: locale,
//   fallbackLocale: 'en',
//   messages,
//   globalInjection: true
// })
Vue.use(VueLodash, { name: 'custom', lodash: lodash })
Vue.use(Carousel3d)
Vue.use(VueMoment)
Vue.use(ToggleButton)
Vue.use(Notifications)
Vue.component('piaf-breadcrumb', Breadcrumb)
Vue.component('b-refresh-button', RefreshButton)
Vue.component('b-colxx', Colxx)
Vue.component('vue-perfect-scrollbar', vuePerfectScrollbar)
Vue.use(require('vue-shortkey'))
Vue.use(contentmenu)
Vue.use(VueLineClamp, {
  importCss: true
})
Vue.use(VCalendar, {
  firstDayOfWeek: 2, // ...other defaults,
  formats: {
    title: 'MMM YY',
    weekdays: 'WW',
    navMonths: 'MMMM',
    input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
    dayPopover: 'L'
  },
  datePickerShowDayPopover: false,
  popoverExpanded: true,
  popoverDirection: 'bottom'
})
Vue.use(VueScrollTo)
Vue.use(VTooltip, {
  defaultHtml: false
})
Vue.use(AnimateCSS)
Vue.component('running-loader', RunningLoader)

Vue.config.productionTip = false

export default new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
