import axios from 'axios'
import * as types from '../mutation-types'
import { userService } from '@/services'

const state = {
  currentUser: null,
  loginError: null,
  loginStatus: false,
  token: null
}
const getters = {
  authCheck: (state) => state.loginStatus !== false,
  currentUser: (state) => state.currentUser,
  loginError: (state) => state.loginError,
  loginStatus: (state) => state.loginStatus,
  token: (state) => state.token
}
const mutations = {
  [types.SET_USER_DATA](state, payload) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${payload.token}`
    state.token = payload.token
    state.loginStatus = true
  },
  [types.SAVE_TOKEN](state, token) {
    state.token = token
    state.loginStatus = true
  },
  [types.FETCH_USER_SUCCESS](state, payload) {
    let user = {
      accountRoleId: payload.account_role_id,
      active: payload.active,
      avatar: payload.avatar,
      email: payload.email,
      firstName: payload.first_name,
      fullName: payload.full_name,
      id: payload.id,
      lastName: payload.last_name,
      onboarding: payload.status === 0 ? true : false,
      simulations: payload.simulations.length
        ? payload.simulations.map((sim) => {
            return {
              id: sim.id,
              simulationRoleId: sim.simulation_role_id,
              simulationRoleName: sim.simulation_role_name,
              simulationTeamId: sim.simulation_team_id,
              simulationTeamName: sim.simulation_team_name
            }
          })
        : []
    }

    state.currentUser = user
    state.loginStatus = true
    state.loginError = null
  },
  [types.UPDATE_USER_ONBOARDING_SUCCESS](state) {
    state.currentUser.onboarding = false
  },
  [types.LOGOUT](state) {
    ;(state.currentUser = null),
      (state.loginError = null),
      (state.token = null),
      (state.loginStatus = false)
  },
  [types.SET_LOGIN_ERROR](state, error) {
    console.log('set login error')
    state.loginError = error
  },
  setLoginError(state, error) {
    console.log('set login error')
    state.loginError = error
  }
}
const actions = {
  async login({ commit, dispatch }, payload) {
    try {
      await commit('loading/SET_LOGIN_PROCESSING', true, { root: true })
      const user = await userService.login(payload)
      await commit('SET_USER_DATA', user.data)
      await commit('loading/SET_LOGIN_PROCESSING', false, { root: true })
      return { status: user.status }
    } catch (error) {
      const notification = {
        type: 'error',
        title: `Error ${error.response.status}`,
        message: error.response.data.message
      }
      await dispatch('notification/addAuthNotification', notification, { root: true })
      await commit('loading/SET_LOGIN_PROCESSING', false, { root: true })
      return { status: 'error', message: error.response.data.message }
    }
  },
  async fetchProfile({ commit, dispatch }) {
    try {
      const { data } = await userService.getUser()
      await commit(types.FETCH_USER_SUCCESS, data.data)

      const notification = {
        type: 'warning',
        title: 'Welcome',
        message: `${data.data.full_name}`
      }
      await dispatch('notification/addNotification', notification, { root: true })
    } catch (error) {
      const notification = {
        type: 'error',
        title: 'Error occurs when fetching user profile',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async updateUserOnboarding({ commit }) {
    try {
      const { data } = await userService.setOnBoarding()
      await commit(types.UPDATE_USER_ONBOARDING_SUCCESS)
    } catch (e) {
      console.log(e)
    }
  },
  async logout({ commit, dispatch }) {
    try {
      await userService.logout()
      await commit(types.LOGOUT)
    } catch (error) {
      const notification = {
        type: 'error',
        title: 'Error occurs when logging out',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async logoutInvalidToken({ commit, dispatch }) {
    await commit(types.LOGOUT)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
