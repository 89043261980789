import axios from 'axios'
import NProgress from 'nprogress'
import store from '@/store'
import router from '@/router'
import * as adminService from './admin'
import * as dashboardService from './dashboard'
import * as pusherService from './pusher'
import * as simulationService from './simulations'
import * as systemService from './system'
import * as userService from './user'

const apiClient = axios.create({
  baseURL: config.VUE_APP_API_URL || process.env.VUE_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Content-Type-Options': 'nosniff',
    'Referrer-Policy': 'no-referrer-when-downgrade',
    'X-XSS-Protection': '1; mode=block',
    'X-Frame-Options': 'DENY',
    'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
    'Content-Security-Policy':
      "default-src 'self' https://cdn.example.com; object-src 'none'; frame- ancestors 'self'; form-action 'self'; report-uri /example-reporting-endpoint"
  }
})

apiClient.interceptors.request.use(
  (config) => {
    NProgress.start()
    const token = store.getters.token
    config.headers.common['Authorization'] = `Bearer ${token}`
    return config
  },
  (error) => {
    NProgress.done()
    return Promise.reject(error)
  }
)

apiClient.interceptors.response.use(
  (response) => {
    // Called on response
    NProgress.done()
    return response
  },
  (error) => {
    if (error && error.response && error.response.status === 401) {
      if (store.getters.loginStatus) {
        // detect invalid token and proceed logout
        if (error.response.data.error_sub_code && error.response.data.error_sub_code === 1000) {
          store.dispatch('logoutInvalidToken')
        } else store.dispatch('logout') // force a log out and reset login status
        router.push('/user/login').catch(() => {})

        if (error.response.data && error.response.status) {
          const notification = {
            type: 'error',
            title: 'Unauthenticated',
            message: error.response.data.status || error.response.data.message
          }
          store.dispatch('notification/addAuthNotification', notification, { root: true })
        }

        // check pusher open channels and close all
        pusherService.disconnect()

        NProgress.done()
        return Promise.reject(error)
      } else {
        NProgress.done()
        return Promise.reject(error) // reject the Promise, with the error as the reason
      }
    } else {
      NProgress.done()
      return Promise.reject(error) // reject the Promise, with the error as the reason
    }
  }
)

export default apiClient
export {
  adminService,
  dashboardService,
  pusherService,
  simulationService,
  systemService,
  userService
}
